import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
const TicketList = () => {
    return (
        <>
            <section className="banner-section">
                <Container>
                    <div className="inner-banner">
                        <Row>
                            <Col md={7}>
                                <h1 className="help-heading">Raised Tickets</h1>
                                <div className="d-flex align-items-center field-wrapper">
                                    <input type="text" className="form-control question-field shadow-none border-0"
                                        placeholder="Search Raised Tickets" />
                                    <button className="search-btn"><FaSearch /></button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section className="raised-wrapper">
                <Container>
                    <div className="inner-raised-wrapper">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h3 className="single-article-heading mb-0">Total Tickets(2)</h3>
                            <select className="form-select form-control w-auto shadow-none ticket-filter request-field">
                                <option value="any">Any</option>
                                <option value="progress">Progress</option>
                                <option className="closed">Closed</option>
                            </select>
                        </div>
                        <Link to={"/ticket-detail"} className="ticket-wrapper d-block mb-3">
                            <Row>
                                <Col md={9}>
                                    <p className="text-eyebrow">#123456790</p>
                                    <h3 className="ticket-heading">Having issue in updating jobs</h3>
                                    <span className="category-text">Profile Updation</span>
                                </Col>
                                <Col md={3}>
                                    <p className="date-ticket">10 May 2024</p>
                                    <div className="d-md-block d-inline-block">
                                        <span className="ticket-status status-finished">Progress</span>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <p className="issue-desc">Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen
                                        book. </p>
                                </Col>
                            </Row>
                        </Link>
                        <Link to={"/ticket-detail"} className="ticket-wrapper d-block mb-3">
                            <Row>
                                <Col md={9}>
                                    <p className="text-eyebrow">#123456790</p>
                                    <h3 className="ticket-heading">Having issue in updating profile name</h3>
                                    <span className="category-text">Profile Updation</span>
                                </Col>
                                <Col md={3}>
                                    <p className="date-ticket">10 May 2024</p>
                                    <div className="d-md-block d-inline-block">
                                        <span className="ticket-status status-closed">Closed</span>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <p className="issue-desc">Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen
                                        book. </p>
                                </Col>
                            </Row>
                        </Link>
                    </div>
                </Container>
            </section>
            <Container>
                <div className="d-flex justify-content-between align-items-center">
                    <p className="pagination-result">Showing results 2 of 2</p>
                    <nav aria-label="Page navigation example">
                        <ul className="pagination custom-pagination justify-content-end">
                            <li className="page-item">
                                <a className="page-link" href="#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            <li className="page-item"><a className="page-link active" href="#">1</a></li>
                            <li className="page-item">
                                <a className="page-link" href="#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </Container>
        </>
    )
}
export default TicketList;