import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import userImg from '../../assets/img/demo-img.jpg';
import rexettIcon from '../../assets/img/icon-logo.png';
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";

const TicketDetail = () => {
    return (
        <>

            <section className="section-top-space article-wrapper">
                <Container>
                    <div className="inner-article-wrapper">
                        <Row>
                            <Col md={12}>
                                <Breadcrumb className="custom-breadcrumb">
                                    <Breadcrumb.Item href="/">Rexett | Help Center</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/ticket-list">Tickets List</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Having issue in updating jobs</Breadcrumb.Item>
                                </Breadcrumb>
                                {/* <nav aria-label="breadcrumb mb-4">
                                    <ol className="breadcrumb custom-breadcrumb">
                                        <li className="breadcrumb-item"><a href="support.html">Rexett | Help Center</a></li>
                                        <li className="breadcrumb-item" aria-current="page"><a href="raised-ticket.html">Raised Ticket</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Having issue in updating profile name</li>
                                    </ol>
                                </nav> */}
                                <div className="mb-4">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <p className="text-eyebrow fw-medium">#123456790</p>
                                        <div className="d-flex align-items-center gap-2">
                                            <p className="time-posted mb-0">12 May 2024</p>
                                            <span className="ticket-status status-finished">Progress</span>
                                        </div>
                                    </div>
                                    <h3 className="single-article-heading d-flex align-items-center gap-2 mb-1">Having issue in updating jobs</h3>
                                    <span className="category-text mb-0">Profile Updation</span>
                                </div>
                                <div className="content-wrapper">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                </div>
                                <div className="attachments-added">
                                    <h3 className="section-heading">Attachments(1)</h3>
                                    <div>
                                        <div className="attahment-wrapper">
                                            <a href="#" className="mb-1"><span>Invoice.png</span> <FaArrowUpRightFromSquare /></a>
                                        </div>
                                        <p className="text-error">No attachements</p>
                                    </div>
                                </div>
                                <div className="helpful-article">
                                    <p className="helpful-heading">Response (2)</p>
                                    <div className="comment-wrapper">
                                        <div className="comment-container">
                                            <div className="comment-info">
                                                <div className="d-flex align-items-center gap-2">
                                                    <div className="comment-pic">
                                                        <img src={userImg} />
                                                    </div>
                                                    <h3 className="comment-user">John Doe</h3>
                                                </div>
                                                <div>
                                                    <p className="comment-date">12 May 2024</p>
                                                </div>
                                            </div>
                                            <div className="comment-written">
                                                <p>Hi, From Janurary 2024, My total number of jobs was not updated. In March, I reported this to Rexett customer care, and to date, I am getting the same answers; the engineer is looking into it, and I will get an update once it is solved. It is now more than 60 days. How much more time does it need to be solved? </p>
                                            </div>
                                        </div>
                                        <div className="comment-container">
                                            <div className="comment-info">
                                                <div className="d-flex align-items-center gap-2">
                                                    <div className="comment-pic">
                                                        <img src={rexettIcon} />
                                                    </div>
                                                    <h3 className="comment-user">Andrew Taylor</h3>
                                                    <div>
                                                        <img src={rexettIcon} className="rexett-comment-icon me-1" />
                                                        <span className="rexett-moderate">Moderator</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="comment-date">12 May 2024</p>
                                                </div>
                                            </div>
                                            <div className="comment-written">
                                                <p>Thank you for reaching out to us. I shared your report with the team handling your case and one of our agents will follow up with you on your support ticket as soon as possible to assist you further. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-comment-wrapper">
                                        <p className="helpful-heading">Add Reply</p>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            id="editor"
                                            data="<p>Type your comment here...</p>"
                                            config={{
                                                toolbar: [
                                                    'bold', 'italic', 'underline', 'strikethrough', '|',
                                                    'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|',
                                                    'bulletedList', 'numberedList', '|',
                                                    'heading', 'removeFormat', 'code'
                                                ],
                                                fontSize: {
                                                    options: [
                                                        'tiny',
                                                        'small',
                                                        'default',
                                                        'big',
                                                        'huge'
                                                    ]
                                                },
                                                fontFamily: {
                                                    options: [
                                                        'default',
                                                        'Arial, Helvetica, sans-serif',
                                                        'Courier New, Courier, monospace',
                                                        'Georgia, serif',
                                                        'Lucida Sans Unicode, Lucida Grande, sans-serif',
                                                        'Tahoma, Geneva, sans-serif',
                                                        'Times New Roman, Times, serif',
                                                        'Trebuchet MS, Helvetica, sans-serif',
                                                        'Verdana, Geneva, sans-serif'
                                                    ]
                                                }
                                            }}
                                            onReady={editor => {
                                                console.log('Editor is ready to use!', editor);
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                console.log({ event, editor, data });
                                            }}
                                            onBlur={(event, editor) => {
                                                console.log('Blur.', editor);
                                            }}
                                            onFocus={(event, editor) => {
                                                console.log('Focus.', editor);
                                            }}
                                        />
                                        <div className="text-end mt-3">
                                            <button className="common-btn font-14">Post a comment</button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}
export default TicketDetail;